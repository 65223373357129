export function setItem(key: string, value: any | null, { sessionOnly = false } = {}) {
  if (value === null) {
    window?.sessionStorage?.removeItem(key)
    window?.localStorage?.removeItem(key)
    return
  }
  if (sessionOnly) {
    window?.sessionStorage?.setItem(key, JSON.stringify(value))
  } else {
    window?.localStorage?.setItem(key, JSON.stringify(value))
  }
}

export function getItem<T>(key: string): T | null {
  const value = window?.sessionStorage?.getItem(key) ?? window?.localStorage?.getItem(key)
  return value ? JSON.parse(value) : null
}
