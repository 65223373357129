import hmac from 'crypto-js/hmac-sha512'
import isNil from 'lodash/isNil'
import useSWR, { type SWRResponse, type SWRConfiguration } from 'swr'
import useSWRImmutable from 'swr/immutable'

import { getItem, setItem } from 'lib/storage'
import { flattenObject } from 'lib/utils'
import type {
  AnyKeys,
  Base,
  HttpBody,
  HttpHeaders,
  HttpMethod,
  HttpParams,
  ListParams,
  ReadParams,
  User,
  UserCredentials
} from './types'

export const _api = process.env.NEXT_PUBLIC_API_HOST ?? 'https://api-dev.lunara.capital'

export const storageCredentialsKey = 'lunara.auth.credentials'

export const storageUserKey = 'lunara.auth.user'

export class BaseService<Model extends Base> {
  api: string
  basePath: string

  constructor({ api, basePath }: { api: string; basePath: string }) {
    this.api = api
    this.basePath = basePath
  }

  get user(): User | null {
    return getItem(storageUserKey)
  }

  set user(newValue: User | null) {
    setItem(storageUserKey, newValue)
  }

  get userCredentials(): UserCredentials | null {
    return getItem(storageCredentialsKey)
  }

  set userCredentials(newValue: UserCredentials | null) {
    setItem(storageCredentialsKey, newValue)
  }

  async read(id: string, params?: ReadParams): Promise<Model> {
    return this.get(`${this.basePath}/${id}`, params ? flattenObject(params) : undefined)
  }

  async create(body: AnyKeys<Model>): Promise<Model> {
    return this.post(`${this.basePath}`, body)
  }

  async list(params?: ListParams<Model>): Promise<Model[]> {
    return this.get(`${this.basePath}`, params ? flattenObject(params) : undefined)
  }

  async readAndUpdate(id: string, body: AnyKeys<Model>): Promise<Model> {
    return this.put(`${this.basePath}/${id}`, body)
  }

  async readAndDelete(id: string, params?: HttpParams): Promise<Model> {
    return this.delete(`${this.basePath}/${id}`, params)
  }

  async get<T>(path: string, params?: HttpParams): Promise<T> {
    return this.request({ path, method: 'get', params })
  }

  async delete<T>(path: string, params?: HttpParams): Promise<T> {
    return this.request({ path, method: 'delete', params })
  }

  async post<T>(path: string, body: HttpBody): Promise<T> {
    return this.request({ path, method: 'post', body })
  }

  async put<T>(path: string, body: HttpBody): Promise<T> {
    return this.request({ path, method: 'put', body })
  }

  async patch<T>(path: string, body: HttpBody): Promise<T> {
    return this.request({ path, method: 'patch', body })
  }

  async request<T>(props: {
    path: string
    method: HttpMethod
    params?: HttpParams
    body?: HttpBody
  }): Promise<T> {
    // Build request
    const req = this.buildAuthRequest(props)

    // Issue request
    const res = await fetch(`${this.api}${req.path}`, {
      method: props.method.toUpperCase(),
      body: req.body,
      headers: {
        'content-type': 'application/json',
        ...req.headers
      }
    })

    // Parse json response
    const json = await res.json()

    // Throw response as an error if we did not receive a 200
    if (!res.ok) {
      if (res.status === 401 && location.pathname !== '/login') {
        window.location.href = `/login?from=${encodeURIComponent(location.pathname)}`
      }
      throw json
    }

    return json
  }

  private buildAuthRequest(props: {
    path: string
    method: HttpMethod
    params?: HttpParams
    body?: HttpBody
  }): { path: string; headers: HttpHeaders; body?: string } {
    const credentials = this.userCredentials

    const timestamp = Date.now()

    let params = undefined
    let body = undefined

    switch (props.method) {
      case 'get':
      case 'delete':
      case 'options': {
        params = props.params
          ? Object.keys(props.params)
              .sort()
              .filter(
                (key: string) => props.params?.[key] !== undefined && props.params?.[key] !== null
              )
              .map((key: string) => [
                encodeURIComponent(key),
                encodeURIComponent(props.params?.[key] as string | number | boolean)
              ])
              .map(([key, value]) => `${key}=${value}`)
              .join('&')
          : ''
        break
      }
      case 'post':
      case 'put':
      case 'patch': {
        body = JSON.stringify(props.body ?? {})
        break
      }
      default:
        throw new Error('Invalid request method')
    }

    const signature = credentials
      ? hmac(`${timestamp}.${props.method}.${props.path}.${params ?? body}`, credentials.privateKey)
      : undefined

    return {
      path: [props.path, params].filter(Boolean).join('?'),
      body: body,
      headers: {
        authorization: credentials ? `bearer ${credentials.publicKey}` : undefined,
        'x-lunara-timestamp': timestamp.toString(),
        'x-lunara-signature': signature?.toString()
      }
    }
  }
}

export class Service<T extends Base> extends BaseService<T> {
  constructor(basePath: string) {
    super({ api: _api, basePath })
  }
}

export class LocalService<T extends Base> extends BaseService<T> {
  constructor(basePath: string) {
    super({ api: 'http://localhost:3000', basePath })
  }
}

export type { SWRConfiguration }

export function useRead<T extends Base>(
  service: Service<T>,
  id: string | undefined,
  params?: ReadParams,
  config?: SWRConfiguration
): SWRResponse<T, Error> {
  const fetcher = () => service.read(id!, params)
  return useSWR(isNil(id) ? null : [`${service.basePath}/${id}`, params], fetcher, config)
}

export function useReadImmutable<T extends Base>(
  service: Service<T>,
  id: string | undefined,
  params?: ReadParams,
  config?: SWRConfiguration
): SWRResponse<T, Error> {
  const fetcher = () => service.read(id!, params)
  return useSWRImmutable(isNil(id) ? null : [`${service.basePath}/${id}`, params], fetcher, config)
}

export function useList<T extends Base>(
  service: Service<T>,
  params?: ListParams<T> | null,
  config?: SWRConfiguration
): SWRResponse<T[], Error> {
  const fetcher = () => service.list(params!)
  return useSWR(params === null ? null : [`${service.basePath}`, params], fetcher, config)
}

export function useListImmutable<T extends Base>(
  service: Service<T>,
  params?: ListParams<T> | null,
  config?: SWRConfiguration
): SWRResponse<T[], Error> {
  const fetcher = () => service.list(params!)
  return useSWRImmutable(params === null ? null : [`${service.basePath}`, params], fetcher, config)
}

export function useGet<T>(
  service: Service<any>,
  path: string,
  params?: HttpParams | null,
  config?: SWRConfiguration
): SWRResponse<T, Error> {
  const fetcher = () => service.get<T>(path, params!)
  return useSWR(params === null ? null : [path, params], fetcher, config)
}

export function useGetImmutable<T>(
  service: Service<any>,
  path: string,
  params?: HttpParams | null,
  config?: SWRConfiguration
): SWRResponse<T, Error> {
  const fetcher = () => service.get<T>(path, params!)
  return useSWRImmutable(params === null ? null : [path, params], fetcher, config)
}

/// Assign debug api service to window object.
/// Useful for issuing requests to api that dont have a UI yet.
if (typeof window !== 'undefined') {
  Object.assign(window, { $api: new Service('') })
}
