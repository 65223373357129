import type { Group } from '@/modules/portfolio/performance-table'
import type { MarketInterval, Pnl, Position } from 'api/types'
import isPlainObject from 'lodash/isPlainObject'
import merge from 'lodash/merge'
import omit from 'lodash/omit'
import { DateTime, type DurationObjectUnits } from 'luxon'
import _slugify from 'slugify'
import { toast } from 'sonner'

export const isServer = typeof window === 'undefined'

export function objectKeys<T extends Record<string, any>>(obj: T) {
  return Object.keys(obj) as Array<keyof T>
}

export function slugify(
  input: string,
  { lower = true, trim = true }: { lower?: boolean; trim?: boolean } = {}
) {
  return _slugify(input, {
    lower,
    trim
  })
}

export function toFixed(
  value: number,
  fractionDigits: number,
  forceDisplayFractionDigits = false
): string {
  try {
    return value.toLocaleString(undefined, {
      minimumFractionDigits: forceDisplayFractionDigits ? fractionDigits : 0,
      maximumFractionDigits: fractionDigits
    })
  } catch (err) {
    return ''
  }
}

const toDecimalPlaces =
  (n: number) =>
  (
    num: number,
    options?: {
      minimumFractionDigits?: number
      maximumFractionDigits?: number
    }
  ) =>
    Number(num).toLocaleString(undefined, {
      minimumFractionDigits: n,
      maximumFractionDigits: n,
      ...options
    })

export function fixed(value: number, debug = false) {
  if (Math.abs(value) < 0.001) {
    return toDecimalPlaces(4)(value, { minimumFractionDigits: 3, maximumFractionDigits: 4 })
  }
  if (Math.abs(value) < 1) {
    return toDecimalPlaces(3)(value)
  }
  const magnitude = Math.abs(Math.floor(Math.log10(Math.abs(value))))

  if (debug) {
    console.log('value | magnitude: ', value, magnitude)
  }
  switch (magnitude) {
    case 0:
      return toThousandths(value)
    case 1:
      return toHundredths(value)
    case 2:
      return toTenths(value)
    default:
      return Math.round(value).toLocaleString()
  }
}

export const toRound = toDecimalPlaces(0)
export const toTenths = toDecimalPlaces(1)
export const toHundredths = toDecimalPlaces(2)
export const toThousandths = toDecimalPlaces(3)

export function toRelativeFixed(value: number): string {
  switch (Math.floor(Math.log10(Math.max(1, Math.abs(value))))) {
    case 0:
      return toThousandths(value, { minimumFractionDigits: 0 })
    case 1:
    case 2:
      return toHundredths(value, { minimumFractionDigits: 0 })
    case 3:
    case 4:
      return toTenths(value, { minimumFractionDigits: 0 })
    default:
      return toRound(value)
  }
}

export function objectId(docOrId: { id: string } | string): string {
  return typeof docOrId === 'string' ? docOrId : docOrId.id
}

export function shortId(docOrId: { id: string } | string): string {
  return objectId(docOrId).slice(0, 6)
}

export function formatBalance(value: number, symbol: string): string {
  const parts = symbol.split('/')
  if (parts.length < 2) {
    return toRelativeFixed(value)
  }
  const base = symbol.split('/')[0]
  if (base?.startsWith('USD') || base?.endsWith('USD')) {
    return `$${toHundredths(value)}`
  }
  return `${toRelativeFixed(value)} ${base}`
}

export function formatPercent(value: number): string {
  return `${toRelativeFixed(value * 100)}%`
}

export function formatPrice(
  value: number,
  currency: string | undefined,
  fractionDigits = 3
): string {
  const formattedValue = toFixed(value, fractionDigits)
  if (!currency) {
    return formattedValue
  }
  if (currency.startsWith('USD')) {
    return `$${formattedValue}`
  }
  return `${formattedValue} ${currency.toUpperCase()}`
}

export function formatPnl(value: number, symbol: string): string {
  if (symbol.endsWith('/BUSD') || symbol.endsWith('/USDT')) {
    return formatBalance(value, 'USD')
  }
  return formatBalance(value, symbol.split('/')[0])
}

export function getGroupPnl(group: Group) {
  const pnl = group.amount
  const formattedPnl = formatPnl(pnl, '/USDT')
  const percent = group.percent
  const percentFormatted = percent ? `${toHundredths(percent * 100)}%` : '-'
  return {
    pnl,
    formattedPnl,
    percent,
    percentFormatted
  }
}

export function getPerformancePnl(performanceInterval: {
  amount: number
  percent: number
}) {
  const pnl = performanceInterval.amount
  const formattedPnl = formatPnl(pnl, '/USDT')
  const percent = performanceInterval.percent
  const percentFormatted = percent ? `${toHundredths(percent * 100)}%` : '-'
  return {
    pnl,
    formattedPnl,
    percent,
    percentFormatted
  }
}

export function formatPnlPnl(pnl: Pnl) {
  const { amount, percent, symbol, cost } = pnl

  const formatted = formatPnl(amount, symbol)
  const percentFormatted = `${fixed(percent * 100)}%`
  const percentChange = percent
  return {
    pnl: amount,
    amount,
    pretty: fixed(amount),
    formatted,
    percent,
    percentFormatted,
    percentChange,
    cost
  }
}

export function getPositionPnl(position: Position) {
  const { realizedPnl, unrealizedPnl, maxUnrealizedPnl, minUnrealizedPnl } = position

  // only use realizedPnl if position is closed: unrealizedPnl is off the table
  const pnl = position.status === 'open' ? realizedPnl + unrealizedPnl : realizedPnl

  const asset = position.symbol.split('/')[0]
  const formatted = formatPnl(pnl, position.symbol)
  const percent = pnl / position.cost
  const percentFormatted = `${toHundredths(percent * 100)}%`
  // const percent = `${percentChange >= 0 ? '+' : '-'}${formatPercent(percentChange)}}`
  return {
    pnl,
    pretty: toRelativeFixed(pnl),
    formatted,
    asset,
    percent,
    percentFormatted,
    breakdown: {
      realized: {
        value: realizedPnl,
        formatted: formatPnl(realizedPnl, position.symbol),
        percent: realizedPnl / position.cost,
        percentFormatted: `${toHundredths((realizedPnl / position.cost) * 100)}%`
      },
      unrealized: {
        value: unrealizedPnl,
        formatted: formatPnl(unrealizedPnl, position.symbol),
        percent: unrealizedPnl / position.cost,
        percentFormatted: `${toHundredths((unrealizedPnl / position.cost) * 100)}%`
      },
      maxUnrealized: {
        value: maxUnrealizedPnl.value,
        formatted: formatPnl(maxUnrealizedPnl.value, position.symbol),
        percent: maxUnrealizedPnl.value / position.cost,
        percentFormatted: `${toHundredths((maxUnrealizedPnl.value / position.cost) * 100)}%`
      },
      minUnrealized: {
        value: minUnrealizedPnl.value,
        formatted: formatPnl(minUnrealizedPnl.value, position.symbol),
        percent: minUnrealizedPnl.value / position.cost,
        percentFormatted: `${toHundredths((minUnrealizedPnl.value / position.cost) * 100)}%`
      },
      total: {
        value: pnl,
        formatted,
        percent,
        percentFormatted
      }
    }
  }
}

export function formatDate(
  value: Date | string,
  format: string,
  options?: { zone?: string }
): string {
  const date = new Date(value)
  return DateTime.fromJSDate(date, options).toFormat(format)
}

export function formatDateRelative(value: Date | string, options?: { zone?: string }) {
  const date = new Date(value)
  return DateTime.fromJSDate(date, options).toRelative()
}

export function formatDateDiffRelative(start: Date | string, end: Date | string) {
  const startDate = new Date(start)
  const endDate = new Date(end)
  const dur = DateTime.fromJSDate(startDate)
    .diff(DateTime.fromJSDate(endDate), ['months', 'days', 'hours', 'minutes', 'seconds'])
    .toObject()
  let relative = ''
  if (dur.months) {
    relative += `${Math.abs(dur.months)}mo `
  }
  if (dur.days) {
    relative += `${Math.abs(dur.days)}d `
  }
  if (dur.hours) {
    relative += `${Math.abs(dur.hours)}h `
  }
  if (dur.minutes) {
    relative += `${Math.abs(dur.minutes).toFixed(0)}m`
  }
  if (!relative && dur.seconds) {
    relative += `${Math.abs(dur.seconds).toFixed(0)}s`
  }
  return relative
}
export function formatUTC(value: Date | string, format = 'MM/dd/yy HH:mm') {
  return formatDate(value, format, { zone: 'UTC' })
}

export function formatUSD(value: number, currency = 'USD') {
  // Create our number formatter.
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency

    // These options are needed to round to whole numbers if that's what you want.
    // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  })

  return formatter.format(value) /* $2,500.00 */
}

export function flattenObject(
  _obj: Record<string, any>,
  _path: string[] = []
): Record<string, any> {
  const _flattenObject = (obj: Record<string, any>, path: string[]): Record<string, any> => {
    return !isPlainObject(obj)
      ? { [path.join('.')]: obj }
      : Object.entries(obj).reduce(
          (acc, [key, value]) => merge(acc, _flattenObject(value, [...path, key])),
          {}
        )
  }
  return _flattenObject(_obj, _path)
}

export async function delay(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

export function handleApiError(message?: string, reason?: string) {
  if (typeof message === 'string' && typeof reason === 'string') {
    toast.error(`${message}: ${reason}`)
    return
  }
  if (typeof message === 'string') {
    toast.error(message)
    return
  }
  if (typeof reason === 'string') {
    toast.error(reason)
  }
}

export function durationObjectUnits(
  interval: MarketInterval | '5m' | '1m',
  units: number
): DurationObjectUnits {
  switch (interval) {
    case '1d':
      return { days: units }
    case '1h':
      return { hours: units }
    case '30m':
      return { minutes: 30 * units }
    case '15m':
      return { minutes: 15 * units }
    case '5m':
      return { minutes: 5 * units }
    case '1m':
      return { minutes: units }
  }
}

export const MARKET_INTERVALS = ['1h', '1d']

export function getParamsWithPagination(
  params?: Record<string, any> & {
    page?: number | null
    limit?: number | null
  },
  opts?: { limit?: number }
) {
  let page = 0
  if (params && typeof params?.page === 'number') {
    page = Number(params.page)
  }
  const limit = params?.limit ?? opts?.limit ?? 250
  const offset = page * limit
  return omit(
    {
      ...params,
      offset,
      limit
    },
    'page'
  )
}

const units: Intl.RelativeTimeFormatUnit[] = [
  'year',
  'month',
  'week',
  'day',
  'hour',
  'minute',
  'second'
]

export const timeAgo = (dateTime: DateTime) => {
  const diff = dateTime.diffNow().shiftTo(...units)
  const unit = units.find((unit) => diff.get(unit) !== 0) || 'second'

  const relativeFormatter = new Intl.RelativeTimeFormat('en', {
    numeric: 'auto',
    style: 'narrow'
  })
  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit)
}
/**
 *
 * @param {Number} num
 * @returns {String} 1K, 1M, 1B
 */
export const abbreviateNumberByMagnitude = (num: number, { fixed }: any = {}): string => {
  const number = Math.abs(num)
  const sign = num >= 0 ? '' : '-'
  const fix = (val: number) => (fixed ? val.toFixed(fixed) : val)
  if (number >= 1000000000000) {
    return `${sign}${fix(number / 1000000000000)}T`
  } else if (number >= 1000000000) {
    return `${sign}${fix(number / 1000000000)}B`
  } else if (number >= 1000000) {
    return `${sign}${fix(number / 1000000)}M`
  } else if (number >= 1000) {
    return `${sign}${fix(number / 1000)}K`
  } else {
    return `${sign}${number}`
  }
}

export function getPositionsBySide(
  positions?: Position[]
): { side: string; percent: number }[] | null {
  if (!positions) return null
  const total = positions.reduce((acc: any, position: any) => {
    return acc + position.cost
  }, 0)
  const positionsBySide = positions.reduce((acc: any, position: any) => {
    const side = position.side
    const percent = position.cost / total
    if (!acc[side]) {
      acc[side] = {
        side,
        percent
      }
    } else {
      acc[side].percent += percent
    }
    return acc
  }, {})
  return Object.values(positionsBySide)
}

export function utc(date?: string | Date | number) {
  if (!date) {
    return DateTime.utc()
  }
  const options = { zone: 'UTC' }
  if (typeof date === 'string') {
    return DateTime.fromISO(date, options).toUTC()
  }
  if (date instanceof Date) {
    return DateTime.fromJSDate(date, options).toUTC()
  }
  if (typeof date === 'number') {
    return DateTime.fromMillis(date, options).toUTC()
  }
  throw new Error('Invalid date')
}
